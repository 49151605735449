import type Stripe from "stripe";

import { CancelSurveyInfo } from "@/components/Account/CancelSubcriptionModal";
import { listProductFeatureMap } from "@/constants";
import { PlanLevel, PricingPlan, WebhookApiType } from "@/types/pricing";

import { client } from "./base";

export const getListProduct = () => {
  return client.get("/portal/get_list_price").then((data) => {
    const plans = data.data;
    const result = plans
      .filter((plan) => {
        const product = plan.product as Stripe.Product;
        return plan.active && product.active && product.metadata && plan.type === "recurring";
      })
      .map((plan) => {
        // ! Should edit metadata.level in Stripe Dashboard
        const product = plan.product as Stripe.Product;
        const planLevel = (product.metadata.level || product.metadata.LEVEL) as PlanLevel;
        const isFreeTrail = product.metadata?.free_trial === "TRUE";

        const isMoreYearsBusiness =
          plan.recurring?.interval === "year" && plan.recurring?.interval_count > 1;

        return {
          name: product.name,
          productId: product.id,
          priceId: plan.id,
          planLevel: planLevel,
          isFreeTrail,
          price: plan.unit_amount,
          interval: isMoreYearsBusiness
            ? `${plan.recurring?.interval_count}year`
            : plan.recurring?.interval
              ? plan.recurring?.interval
              : "year",
          list: listProductFeatureMap.get(planLevel) ?? [],
        } as PricingPlan;
      });

    // console.info(`---result`, result);
    return result;
  });
};
export const getScheduleList = (stripeCustomerId) => {
  return client
    .get("/stripe/list_stripe_schedules", {
      params: {
        stripe_customer_id: stripeCustomerId,
      },
    })
    .then((res: any) => {
      const { schedules, schedulesProductInfo = {} } = res;
      schedules.forEach((schedule: any) => {
        const scheduleProduct = schedulesProductInfo[schedule.id];
        schedule.scheduleProduct = scheduleProduct;
      });
      return schedules;
    });
};

export const createSchedule = (data: {
  customer_id: string;
  price_id: string;
  start_date: number;
}) => {
  return client.post("/stripe/create_schedule", data);
};

export const getUpdatePaymentTypeUrl = (data: {
  success_url: string;
  cancel_url: string;
  stripe_subscription_id: string;
  stripe_customer_id: string;
}): Promise<{
  checkout_url: string;
}> => {
  return client.post("/stripe/create_setup_checkout", data);
};

export const getPayInvoice = (): Promise<any> => {
  return client.post("/stripe/pay_invoice");
};

/**
 * @params stripe_checkout_id  https://stripe.com/docs/payments/checkout/subscriptions/update-payment-details#create-checkout-session
 */
export const updateCustomerPaymentMethod = (stripe_checkout_id: string) => {
  return client.post("/stripe/update_customer_default_payment_method", {
    stripe_checkout_id,
  });
};

export const resumeSubscription = () => {
  return client.post("/stripe/resume_subscription");
};

export const cancelSubscriptionWithReason = (data: {
  type: WebhookApiType;
  data: CancelSurveyInfo;
}) => {
  return client.post("/portal/cancel_subscription_survey", data);
};

export const cancelSubscription = () => {
  return client.post("/stripe/cancel_subscription");
};

export const getListSubscriptions = (
  stripe_customer_id: string
): Promise<{ subscriptions: Stripe.Subscription[] }> => {
  return client.get("/stripe/list_subscriptions", {
    params: {
      stripe_customer_id,
    },
  });
};

export const getSubscription = (stripe_subscription_id: string) => {
  return client
    .get("/stripe/get_subscription", { params: { stripe_subscription_id } })
    .then((data: any) => {
      const result = data.subscription as Stripe.Subscription;
      return result;
    });
};

export const getCustomerPaymentInfo = (data: {
  stripe_customer_id: string;
}): Promise<{ data: Stripe.PaymentMethod[] }> => {
  return client.get("/stripe/get_customer_payment_methods", { params: data });
};

export const getListInvoice = (
  stripe_subscription_id: string
): Promise<{ data: Stripe.Invoice[] }> => {
  return client.post("/stripe/list_invoice", {
    stripe_subscription_id,
  });
};
