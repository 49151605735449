import { atom } from "jotai";
import { atomWithReset, loadable, selectAtom } from "jotai/utils";
import { isEqual } from "lodash";
import type Stripe from "stripe";

import { getScheduleList, getSubscription } from "@/client/payment";
import { batchFetchTwitterUser } from "@/client/search";
import { searchTwitterUser } from "@/client/search";
import { getOwnerInfo, getPortalUserInfo } from "@/client/userInfo";
import { OwnerSubInfo, PortalUserInfo } from "@/types/userInfo";
import { levelLocalStorageUtil } from "@/utils";

export const userInfoBaseAtom = atomWithReset(
  {} as PortalUserInfo & {
    isSimultaneousLogin?: boolean;
  }
);

let initialSearchParams: URLSearchParams;

export const userInfoAtom = atom(
  (get) => {
    return get(userInfoBaseAtom);
  },
  async (get, set) => {
    try {
      const prevUserInfo = get(userInfoBaseAtom);
      const userInfo = await getPortalUserInfo();

      try {
        // screen name is twitter handle, when not has, should search
        if (userInfo?.twitterInfo?.twitter_user_id && !prevUserInfo?.twitterInfo?.twitter_user_id) {
          if (userInfo.twitterInfo?.twitter_screen_name) {
            userInfo.twitterInfo.twitter_user_name = userInfo.twitterInfo.twitter_screen_name;
          } else {
            const res = await searchTwitterUser({
              twitter_user_id: userInfo?.twitterInfo?.twitter_user_id,
            });
            const user = res?.listData?.[0];
            if (userInfo?.twitterInfo?.twitter_user_id === user?.id) {
              userInfo.twitterInfo.twitter_user_name = user.username;
            }
          }
        } else if (prevUserInfo?.twitterInfo?.twitter_user_id) {
          userInfo.twitterInfo = prevUserInfo.twitterInfo;
        }
      } catch (e) {
        console.error(e);
      }

      // Save the level information to local storage
      // so that it can be compared when userInfo is updated to determine if the token needs to be refreshed.
      try {
        const currLevel = levelLocalStorageUtil.getValue();
        if (!currLevel || currLevel !== userInfo.membershipLevel) {
          levelLocalStorageUtil.setValue(userInfo.membershipLevel);
        }
      } catch (e) {
        console.error("levelLocalStorageUtil error", e);
      }

      const nextUserInfo = {
        ...prevUserInfo,
        ...userInfo,
        isSimultaneousLogin: false,
        // TODO: remove mock data
        // stripeCustomerId: undefined,
        // stripeProductId: undefined,
        // stripeSubscriptionId: undefined,
        // membershipLevel: PlanLevel.Business,
        // freeTrialRequested: true,
        // stripeDemoRequested: true,
        // recoverFromFreeTrial: true,
      };
      if (
        (!userInfo?.slackAccessToken || !userInfo?.slackChatId) &&
        (prevUserInfo.slackAccessToken || prevUserInfo.slackChatId)
      ) {
        delete nextUserInfo.slackAccessToken;
        delete nextUserInfo.slackChatId;
      }
      if (!initialSearchParams) {
        initialSearchParams = new URLSearchParams(location.search);
      }

      // for test only kaito.ai email can use this feature
      if (userInfo?.email?.includes("@kaito.ai")) {
        const mockKeys = [
          "email",
          "stripeCustomerId",
          "stripeProductId",
          "stripeSubscriptionId",
          "membershipLevel",
          "freeTrialRequested",
          "stripeDemoRequested",
          "recoverFromFreeTrial",
        ];
        mockKeys.forEach((key) => {
          if (initialSearchParams.has(key)) {
            nextUserInfo[key] = initialSearchParams.get(key);
          }
        });
      }

      if (!isEqual(prevUserInfo, nextUserInfo)) {
        set(userInfoBaseAtom, nextUserInfo);
      }

      return nextUserInfo;
    } catch (e) {
      // if get_user_info api return 405/409 that indicates there's a simultaneous login error, sign out
      // The ip address or userAgent of the current user is changed.
      if (e.response.status === 405 || e.response.status === 409) {
        set(userInfoBaseAtom, {
          ...get(userInfoBaseAtom),
          isSimultaneousLogin: true,
        });
      }
      throw e;
    }
  }
);

const twitterUserIdAtom = selectAtom(userInfoAtom, (userInfo) => {
  return userInfo.twitterInfo?.twitter_user_id;
});

export const refreshTwitterUserInfoAtom = atom(0);

export const userInfoWithTwitterAsyncAtom = loadable(
  atom(async (get) => {
    const twitterUserId = get(twitterUserIdAtom);
    get(refreshTwitterUserInfoAtom);
    if (!twitterUserId) return null;

    const twitterUserInfos = await batchFetchTwitterUser([twitterUserId]);
    return twitterUserInfos?.listData?.[0];
  })
);

export const subscriptionBaseAtom = atomWithReset(null as Stripe.Subscription);

export const subscriptionAtom = atom(
  (get) => {
    return get(subscriptionBaseAtom);
  },
  async (get, set) => {
    const prev = get(subscriptionBaseAtom);
    const userInfo = get(userInfoBaseAtom);

    if (!userInfo.stripeSubscriptionId) return prev;
    const subscription = await getSubscription(userInfo.stripeSubscriptionId);

    const next = {
      ...prev,
      ...subscription,
      // TODO: remove mock data
      // status: "trialing" as Stripe.Subscription.Status,
    };

    if (!isEqual(prev, next)) {
      set(subscriptionBaseAtom, next);
    }

    return next;
  }
);
export const schedulesBaseAtom = atomWithReset([]);

export const scheduleAtom = atom(
  (get) => {
    return get(schedulesBaseAtom);
  },
  async (get, set) => {
    const prev = get(schedulesBaseAtom);
    const userInfo = get(userInfoBaseAtom);

    if (!userInfo.stripeCustomerId) return prev;
    const subscription = await getScheduleList(userInfo.stripeCustomerId);

    const next = subscription;

    if (!isEqual(prev, next)) {
      set(schedulesBaseAtom, next);
    }

    return next;
  }
);

export const ownerInfoBaseAtom = atomWithReset({} as OwnerSubInfo);

export const ownerInfoAtom = atom(
  (get) => {
    return get(ownerInfoBaseAtom);
  },
  async (get, set) => {
    try {
      const prevOwnerInfo = get(ownerInfoBaseAtom);
      const ownerInfo = await getOwnerInfo();
      const nextUserInfo = {
        ...prevOwnerInfo,
        ...ownerInfo.data,
      };

      if (!isEqual(prevOwnerInfo, nextUserInfo)) {
        set(ownerInfoBaseAtom, nextUserInfo);
      }

      return nextUserInfo;
    } catch (e) {
      throw e;
    }
  }
);
